<template>
  <div>
    <p class="font-medium capitalize">{{ title.replaceAll('_', ' ') }}</p>
    <div class="mt-5 border overflow-x-auto border-primary-grey rounded-lg pb-0.5">
      <table class="w-full table-auto whitespace-nowrap bg-primary-white">
        <thead class="bg-bright-gray">
          <tr class="text-left">
            <th class="py-5 font-medium rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0">
              <span class="ltr:border-r border-primary-grey w-full block rtl:pl-3 ltr:pr-3">
                Roles
              </span>
            </th>
            <th
              v-for="(head, ind) in modules.table_headings"
              :key="ind"
              class="py-5 font-medium rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
            >
              <span
                class="ltr:border-r border-primary-grey w-full rtl:pl-3 ltr:pr-3 capitalize flex relative items-center"
              >
                {{ head && head.heading.replaceAll('_', ' ') }}
                <TooltipIcon
                  v-if="head.more_info"
                  :term="head.more_info"
                  :dynamic-message="true"
                  :height="20"
                  :width="20"
                  custom-tooltip="-ml-52"
                  color="primary-purple-700"
                />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(subModule, index) in modules.table_rows"
            :key="index"
            class="border-t border-primary-grey text-left ml-"
          >
            <td class="rtl:border-l ltr:border-r border-primary-grey rtl:text-right px-4 py-5">
              <span class="font-roboto font-normal text-text-color capitalize text-sm">
                {{ index.replaceAll('_', ' ') }}
              </span>
            </td>
            <td
              v-for="(value, idx) in subModule"
              :key="`sub-module${idx}`"
              class="rtl:border-l ltr:border-r md:w-40 border-primary-grey rtl:text-right px-3"
            >
              <div v-if="!value.not_applicable" class="flex justify-center items-center">
                <UiCheckBox
                  :key="`forceRenderCheckbox${forceRender}-${idx}`"
                  :model-value="value.is_permissible"
                  :halt-update="value.is_locked"
                  @update:modelValue="updateSetting(value, 'checkbox')"
                />
                <span
                  class="block box-border h-6 border_width border-primary-grey mx-2 visible"
                ></span>
                <UiLockButton
                  :model-value="value.is_locked"
                  :deactivate="value.is_locked_at_upper_level"
                  v-bind="value"
                  :class="[{ 'pointer-events-none': currentSectionScope }]"
                  @update:modelValue="updateSetting(value, 'lock')"
                />
              </div>
              <div v-else class="flex justify-center">
                <span class="text-4xl flex items-center text-gray-600 h-6">--</span>
                <span
                  class="block box-border h-6 border_width border-primary-grey ltr:mx-2 rtl:mx-2 visible"
                ></span>
                <span class="text-4xl flex items-center text-gray-600 h-6">--</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* 3rd Party Apis */
import { mapActions, mapState } from 'vuex'
/* COMPONENTS */
import UiLockButton from '@src/components/UiElements/UiLockAnimation.vue'
import UiCheckBox from '@src/components/UiElements/UiCheckbox.vue'
import TooltipIcon from '@src/router/views/landingPage/TooltipIcon.vue'

/* UTILS */
import { objectDeepCopy, removeEmptyKeysFromObject } from '@utils/generalUtil'

export default {
  components: {
    UiCheckBox,
    UiLockButton,
    TooltipIcon,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    title: {
      type: String,
      default: 'Role',
    },
    parentModule: {
      type: String,
      default: '',
    },
    modulesArray: {
      type: [Array, Object],
      default: () => ({}),
    },
  },
  emits: ['updateSettings'],
  data: function () {
    return {
      isLocked: '',
      modules: objectDeepCopy(this.modulesArray),
      settings: 'settings',
      forceRender: 0,
    }
  },
  computed: {
    ...mapState('layout', ['currentSectionScope']),
  },
  methods: {
    async updateSetting(value, type) {
      let res, err
      if (type === 'lock' && this.currentSectionScope) return
      removeEmptyKeysFromObject(value)
      this.updateObject(value, type)
      delete value.id
      switch (this.parentModule) {
        case 'fee_types': {
          ;[res, err] = await this.updateFeePermissionSettings(value)
          break
        }
        case 'campus-attendance': {
          ;[res, err] = await this.updateCampusAttandaceSettings(value)
          break
        }
        case 'section-attendance': {
          ;[res, err] = await this.updateSectionAttandaceSettings(value)
          break
        }
        case 'period-attendance': {
          ;[res, err] = await this.updatePeriodAttandaceSettings(value)
          break
        }
        case 'syllabus-template': {
          ;[res, err] = await this.updateSyllabusTemplateSettings(value)
          break
        }
        case 'class': {
          ;[res, err] = await this.updateClassSettings(value)
          break
        }
        case 'exam_planner': {
          ;[res, err] = await this.updateExamPlannerSettings(value)
          break
        }
        case 'syllabus': {
          ;[res, err] = await this.updateSyllabusPermissionSettings(value)
          break
        }
        case 'time-table': {
          ;[res, err] = await this.updateTimeTableSettings(value)
          break
        }
        case 'staff': {
          ;[res, err] = await this.updateStaffSettings(value)
          break
        }
        case 'salary': {
          ;[res, err] = await this.updateSalaryPermissionSettings(value)
          break
        }
        case 'schedule': {
          ;[res, err] = await this.updateSchedulePermissionSettings(value)
          break
        }
        case 'lesson-planner': {
          ;[res, err] = await this.updateLessonPlannerPermissionSettings(value)
          break
        }
        case 'exam': {
          ;[res, err] = await this.updateExamPermissionSettings(value)
          break
        }
        case 'section': {
          ;[res, err] = await this.updateSectionSettings(value)
          break
        }
        case 'pickup': {
          ;[res, err] = await this.updatePickupPermissionSettings(value)
          break
        }
        case 'section-teacher': {
          ;[res, err] = await this.updateTeacherSettings(value)
          break
        }
      }
      if (res) {
        this.$emit('updateSettings')
      } else {
        this.updateObject(value, type)
      }
      value.id = Math.random() * 100000
      this.forceRender++
    },
    updateObject(object, type) {
      switch (type) {
        case 'checkbox': {
          object.is_permissible = !object.is_permissible
          break
        }
        case 'lock': {
          object.is_locked = !object.is_locked
          break
        }
      }
    },
    /* ACTIONS */
    ...mapActions('settings', [
      'updateClassSettings',
      'updateTimeTableSettings',
      'updateSectionSettings',
      'updateStaffSettings',
      'updateExamPlannerSettings',
      'updateTeacherSettings',
      'updateCampusAttandaceSettings',
      'updatePeriodAttandaceSettings',
      'updateSectionAttandaceSettings',
      'updateFeePermissionSettings',
      'updateSyllabusTemplateSettings',
      'updateSyllabusPermissionSettings',
      'updateSalaryPermissionSettings',
      'updateSchedulePermissionSettings',
      'updateLessonPlannerPermissionSettings',
      'updateExamPermissionSettings',
      'updatePickupPermissionSettings',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.border_width {
  border-width: 0.5px;
}
</style>

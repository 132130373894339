<template>
  <label
    class="success input-field__checkbox relative min-h-[23px] font-inter text-primary-gray-700 flex items-center w-fit"
    :class="lableDirection"
  >
    <div
      v-if="label"
      class="tracking-wider self-center"
      :class="[size === 'sm' ? 'text-sm' : 'text-base']"
    >
      {{ label }}
      <span v-if="rules.includes('required')" class="text-text-color-danger pl-1">*</span>
    </div>
    <input
      :id="labelId"
      :name="name"
      :type="type"
      :checked="modelValue"
      :value="value"
      :disabled="disabled || haltUpdate"
      @change="handleInput"
    />
    <span
      v-if="disabled && !circleShape"
      class="disabled-input rounded absolute top-[2px] left-0 flex items-center justify-center border-2 border-primary-grey bg-primary-white w-5 h-5"
    ></span>
    <span
      v-else
      class="flex items-center justify-center bg-primary-white after:absolute after:hidden after:w-[6px] after:h-[11px] after:mt-[-3px] after:border after:border-primary-purple-700 rounded border-primary-purple-700 border"
      :class="[
        { checkmark: !circleShape, circleShape },
        checkboxDisabledClasses,
        size === 'sm' ? 'w-4 h-4 ' : 'w-5 h-5',
      ]"
    ></span>
  </label>
  <!-- ERRORS -->
  <!-- <div v-if="!noError" class="w-full info-wrapper">
    <div class="flex justify-end h-5">
      <small v-if="errors[0]" class="error-msg rtl:mt-1 ltr:-mt-0">
        {{ translatedError(errors[0]) }}
      </small>
    </div>
  </div> -->
  <!-- </ValidationProvider> -->
</template>

<script>
import { translatedError } from '@src/utils/generalUtil.js'
export default {
  name: 'UiCheckBox',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    size: {
      type: String,
      default: '',
    },
    lableDirection: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Boolean, String],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    name: {
      type: String,
      default: '',
    },
    noError: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    circleShape: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validationMode: {
      type: String,
      default: 'aggressive',
    },
    haltUpdate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      translatedError,
      inputValue: '',
    }
  },
  computed: {
    /*
     * Generates a random ID for the given label.
     * @param {void} Nothing.
     * @retuns {inputId} string - randomly generated id for the labelId.
     */
    labelId() {
      return `inputId${this.getRandomInt()}`
    },
    checkboxDisabledClasses() {
      return this.disabled || this.haltUpdate ? 'cursor-not-allowed' : 'cursor-pointer'
    },
  },
  methods: {
    handleInput(event) {
      if (event.target.checked) {
        this.inputValue = true
      } else this.inputValue = ''
      this.$emit('update:modelValue', event.target.checked)
      this.$emit('change', { status: event.target.checked, value: event.target.value })
    },
    /*
     * Generates a random number in the range of 1 - 1000000000.
     * @param {void} Nothing.
     * @retuns {integer} integer - which is a random integer.
     */
    getRandomInt() {
      const min = 1
      const max = 1000000000
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
  },
}
</script>

<style lang="scss">
// checkbox css
.input-field__checkbox {
  user-select: none;

  .disabled-input::after {
    position: absolute;
    width: 12px;
    height: 12px;
    content: '';
    background-color: var(--primary-grey);
  }

  .checkmark::after {
    content: '';
    border-width: 0 0.125em 0.125em 0;
    transform: rotate(45deg);
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
  }

  .checkmark:hover ~ .tooltipbox {
    visibility: visible;
  }

  input:checked ~ .checkmark {
    background-color: var(--bg-white);
    border: 0.0625em solid var(--bg-white);
  }

  input:checked ~ .checkmark::after {
    display: block;
  }
}
</style>

<style lang="scss" scoped>
/* CIRCLE */

.radio-button {
  padding-right: 25px;
  cursor: pointer;
}

input[type='radio'] {
  position: absolute;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  padding: 11px 23px;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  outline: 0;
  opacity: 0;
  transform: scale(0.75);
}

.circleShape {
  position: absolute;
  top: 4px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-color: var(--primary-white);
  border: 2px solid var(--primary-grey);
  border-radius: 24px;
}

input[type='radio']:checked + .circleShape::after {
  position: absolute;
  top: 2px;
  left: 2px;
  display: block;
  width: 8px;
  height: 8px;
  content: '';
  background-color: var(--primary-purple-700);
  border-radius: 12px;
}

input[type='radio']:checked + .circleShape {
  border-color: var(--primary-purple-700);
}

/* CIRCLE */

.input-field__checkbox .checkmark {
  border: 2px solid var(--primary-grey);
}
.success {
  input:checked ~ .checkmark {
    background-color: var(--primary-white);
    border: 1px solid var(--primary-purple-700);
  }
}
</style>

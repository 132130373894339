<template>
  <div>
    <UiOnClickOutside :do="closeToolTip">
      <div
        class="flex-item ml-2 rtl:mr-2 self-center cursor-pointer"
        @click="clickToolTip"
        @mouseover="displayToolTip"
        @mouseleave="hideToolTip"
      >
        <icon
          class="icon opacity-100 ltr:mr-2 rtl:ml-2"
          :color="color"
          icon="infoCircle"
          :height="height"
          :width="width"
        />
        <div v-if="showToolTip" class="absolute -ml-4 z-40 mt-3 md:mt-2 rtl:-mr-4">
          <Tooltip
            class="w-min sm:w-auto"
            :text="dynamicMessage ? term : $t(`landingPage.${term}`)"
            direction="left"
            :custom-tooltip="customTooltip"
          />
        </div>
      </div>
    </UiOnClickOutside>
  </div>
</template>

<script>
//import icon from '@components/icons/icon.vue'
import tooltipMixin from '@/src/mixins/components/tooltip-mixin.js'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import Tooltip from '@components/tooltip.vue'
import icon from '@components/icons/icon.vue'

export default {
  name: 'TooltipIcon',
  components: {
    UiOnClickOutside,
    icon,
    Tooltip,
  },
  mixins: [tooltipMixin],
  props: {
    term: {
      type: String,
      default: '',
    },
    dynamicMessage: { type: Boolean, default: false },
    height: { type: [String, Number], default: '18' },
    width: { type: [String, Number], default: '18' },
    color: {
      type: String,
      default: 'primary-grey',
    },
    customTooltip: { type: String, default: '' },
  },
}
</script>

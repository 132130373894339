<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="PERIOD_ATTENDANCE_PERMISSION"
      tooltip-title="PERIOD_ATTENDANCE_PERMISSION"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="minHieght80 flex-col">
      <div v-if="!loading">
        <AttandanceCheckbox
          v-for="(val, ind) in settingsArray"
          :key="ind"
          class="mt-6"
          :title="ind"
          parent-module="period-attendance"
          :modules-array="val"
          @updateSettings="getSettings"
        />
      </div>
      <Loader v-else class="mt-28" :content="true" />
    </div>
  </div>
</template>

<script>
/**
 * @notes IMPORTANT NOTES
 * index ACTION FOR VIEW
 * create ACTION FOR MARK
 *  */
/* COMPONENTS */
import TitleWrapper from '@components/TitleWrapper.vue'
import AttandanceCheckbox from '@src/components/Settings/attandance-settings/PermissionSettingsCheckbox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { removeExtraRolePermissions } from '@utils/permissions'
/* 3rd party Component */
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    TitleWrapper,
    AttandanceCheckbox,
    Loader,
  },
  page: {
    title: 'Period Attendance Permission',
    meta: [
      {
        name: 'description',
        content: 'Attendance Settings',
      },
    ],
  },
  data: function () {
    return {
      settingsArray: [],
      loading: false,
      excludeRoleSettings: ['campus_moderator', 'section_moderator', 'section_admin'],
    }
  },

  computed: {
    ...mapState('layout', ['currentCampusScope', 'currentSectionScope', 'currentClassScope']),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.getSettings()
      },
      deep: true,
    },
    currentSectionScope: {
      handler() {
        this.getSettings()
      },
      deep: true,
    },
    currentClassScope: {
      handler() {
        this.getSettings()
      },
    },
  },
  mounted() {
    this.getSettings()
  },

  methods: {
    async getSettings() {
      this.loading = true
      const [res, err] = await this.getPeriodAttandaceSettings()
      removeExtraRolePermissions(this.excludeRoleSettings, res.data)
      this.settingsArray = res.data
      this.loading = false
    },
    ...mapActions('settings', ['getPeriodAttandaceSettings']),
  },
}
</script>

import isEmpty from 'lodash/isEmpty'

export const removeExtraRolePermissions = (roles, permissions) => {
  for (const [key, value] of Object.entries(permissions)) {
    roles.forEach((role) => {
      if (value.table_rows[role]) delete value.table_rows[role]
    })

    if (isEmpty(value.table_rows)) delete permissions[key]
  }
}
